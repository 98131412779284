<template>
  <div class="abitur-table">
    <ui-loading v-if="data.length == 0" label="Cargando Reporte"></ui-loading>
    <div class="table1_container" v-if="data.length > 0">
      <!-- v-if="firstTable != null" -->
      <table width="100%" cellspacing="0" cellpadding="0" class="abiturmatrix1">
        <thead>
          <tr>
            <td rowspan="2"></td>
            <td rowspan="2" colspan="2">
              Fach/Unterrichtssprache
              <br />Subject/Language of instruction
            </td>
            <td colspan="4">
              Punktzahl der Halbjahresleistungen in einfacher Wertung in der
              <br />Qualifikationsphase / Single value credit points in each semester
            </td>
          </tr>
          <tr>
            <td>
              1. Halbjahr
              <br />1st Semester
            </td>
            <td>
              2. Halbjahr
              <br />2nd Semester
            </td>
            <td>
              3. Halbjahr
              <br />3rd Semester
            </td>
            <td>
              4. Halbjahr
              <br />4th Semester
            </td>
          </tr>
        </thead>
        <template v-for="(area, pos) in firstTable">
          <template v-for="(record, i) in area">
            <tr :key="pos+'_'+i">
              <td v-if="i === 0" :rowspan=area.length > 
                <div v-if="pos == 'lang'">
                  Sprachlich-<br/>
                  literarisch-<br/>
                  künstlerisches Aufgabenfeld
                </div>
                <div v-if="pos == 'social'">
                  Gesellschaftswissen-<br/>
                  schaftliches Aufgabenfeld
                </div>
                <div v-if="pos == 'math'">
                  Mathematisch-<br/>
                  naturwissenschaftlich-<br/>
                  technisches<br/>
                  Aufgabenfeld
                </div>
                
              </td>
              <template v-for="(grade, k) in record">
                <td :key="k" v-if="k < 1">{{ grade.subject_name }}</td>
                <td :key="k+1" v-if="k < 1" style="text-align:center">
                  <span v-if="secondTableNames.indexOf(grade.subject_name) >= 0">
                    P{{secondTableNames.indexOf(grade.subject_name)+1}}
                  </span>
                </td>
                <template v-if="record.length == 1 && grade.course_code.includes('12')">
                  <td :key="grade.id+k+1">?</td>
                  <td :key="grade.id+k+2">?</td>
                </template>
                <template v-if="grade.course_code.includes('12') && simulator">
                  <td :key="grade.course_code+k+1"><input type="text" v-model="data[grade.arrayIndex].ordinary"></td>
                  <td :key="grade.course_code+k+2"><input type="text" v-model="data[grade.arrayIndex].extraordinary"></td>
                </template>
                <td
                  v-if="grade.course_code.includes('11') || !simulator"
                  :key="grade.id"
                  @click="grade.ordinary && clickable? toggle(grade, 'filter', $event): null"
                  :class="['matrixcell', {activeFilter: grade.ordinaryMark}, {clickable: clickable}]"
                ><span v-if="grade.ordinary">{{ grade.ordinary }}</span><span v-else>{{'\xa0'}}</span></td>
                <td
                  v-if="grade.course_code.includes('11') || !simulator"
                  :key="grade.id+k"
                  @click="grade.extraordinary && clickable? toggle(grade, 'filter_extra', $event): null"
                  :class="['matrixcell', {activeFilter: grade.extraordinaryMark}, {clickable: clickable}]"
                ><span v-if="grade.extraordinary">{{ grade.extraordinary }}</span><span v-else>{{'\xa0'}}</span></td>
                <template v-if="record.length == 1 && grade.course_code.includes('11')">
                  <td :key="grade.id+k+1"></td>
                  <td :key="grade.id+k+2"></td>
                </template>
              </template>
            </tr>
          </template>   
        </template>
      </table>
    </div>
    <div v-if="simulator">
        <input type="button" value="press me" @click="firstAverage()">
    </div>
    <!-- <p>MARKS : {{ marksAvailabe }}</p> -->
    <br />
    <br />
    <br />
    <div class="halbja" v-if="data.length > 0">
      <div class="data">
        <table width="100%" cellspacing="0" cellpadding="10" class="abiturhalbja">
          <tr>
            <td :class="['left', {error: belegt < 40}]"> belegt: </td>
            <td :class="['enclosed',{error: belegt < 40}]"> {{ belegt }}</td>
            <td class="right" :class="{error: belegt < 40}"> <span v-if="belegt >= 40">>=</span><span v-else> < </span> 40 </td>
          </tr>
          <tr>
            <td :class="['left', {error:(belegt - marksAvailabe) != 36}]">eingebracht: </td>
            <td :class="['enclosed',{error:(belegt - marksAvailabe) != 36}]">{{ belegt - marksAvailabe }}</td>
            <td class="right" :class="{error:(belegt - marksAvailabe) != 36}"> <span v-if="(belegt - marksAvailabe) == 36"> = </span><span v-else>!=</span> 36 </td>
          </tr>
          <tr>
            <td :class="['left', {error:punkte < 29}]">über 4 Punkte:</td>
            <td :class="['enclosed',{error:punkte < 29}]">{{ punkte }}</td>
            <td class="right" :class="{error:punkte < 29}"> <span v-if="punkte >= 29">>=</span><span v-else> < </span> 29 </td>
          </tr>
          <tr>
            <td class="empty"></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td :class="['left', {error:gesamt < 200}]">Punkte gesamt: </td>
            <td :class="['enclosed',{error:gesamt < 200}]"> {{ gesamt }}</td>
            <td class="right" :class="{error:gesamt < 200}"> <span v-if="gesamt >= 200">>=</span><span v-else> < </span> 200</td>
          </tr>
        </table>
      </div>
    </div>
    <br />
    <br />
    <br />
    <div class="table2_container">
      <div v-if="secondTable.length > 0 ">
        <table width="90%" cellspacing="0" cellpadding="0" class="abiturmatrix2">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>schriftlich</td>
              <td>mündlich</td>
            </tr>
          </thead>
          <template v-for="(record, i) in secondTable">
            <tr :key="i">
              <td>P{{ record.iorder - 35 }}</td>
              <td>{{ record.subject_name }}</td>
              <template v-if="!simulator">
                <td class="centered">{{ record.extra3 }}</td>
                <td class="centered">{{ record.extra4 }}</td>
              </template>
              <template v-else>
                <td class="centered"><input type="text" v-model="data[record.arrayIndex].extra3" pattern="\d{2}$"></td>
                <td class="centered"><input type="text" v-model="data[record.arrayIndex].extra4" pattern="\d{2}$"></td>
              </template>
            </tr>
          </template>
        </table>
      </div>
      <div v-else>No information for this table</div>
      <div v-if="secondTable.length > 0 ">
        <table width="90%" cellspacing="0" cellpadding="0" class="abiturmatrix2">
          <tr>
            <td colspan="2" class="bless">
              <br />
            </td>
          </tr>
          <tr>
            <td>Q</td>
            <td class="centered">{{ thisIsQ }}</td>
          </tr>
          <tr>
            <td>A</td>
            <td class="centered">{{ thisIsA }}</td>
          </tr>
          <tr>
            <td>Q + A</td>
            <td class="centered">{{ thisIsA + thisIsQ }}</td>
          </tr>
          <tr>
            <td>Durchschnitt</td>
            <td class="big centered">{{ abiturGrade["name"] }}</td>
          </tr>
        </table>
      </div>
      <div v-else>Missing information for totals table</div>
    </div>
  </div>
</template>



<script>
import useApi from "@/modules/api/mixins/useApi";
import abiturApi from "@/modules/v3/api/abitur/anexo7.js";
import { UiIcon, UiLoading } from "@/modules/ui/components";

export default {
  name: "abitur-table",
  mixins: [useApi],
  api: abiturApi,
  components: { UiLoading },
  props: {
    act_id: {
      //type: Number,
      required: false,
      default: 11
    },
    student_id: {
      // type: Number,
      required: false,
      default: 1045 //1409
    },
    year_id: {
      // type: Number,
      required: false,
      default: 15 //14
    },
    filter_id: {
      // type: Number,
      required: false,
      default: 2
    },
    max_filtered: {
      type: Number,
      required: false,
      default: 12
    },
    scheme_id: {
      type: Number,
      required: false,
      default: 174
    },
    clickable: {
      type: Boolean,
      required: false,
      default: true
    },
    simulator: {
      type: Boolean,
      required: false,
      default: false
    },
    st_simulation: {
      type: Array,
      required: false,
      default: null
    }
  },

  data() {
    return {
      data: [],
      abiturGrade: 0
    };
  },

  created() {
    // if (this.simulator){
    //   this.clickable = false;
    // }
    this.fetchData();
    this.getGrade();
  },

  // mounted() {
  //   console.log('AQUI ESTOY! ', this.clickable);
  // },

  computed: {
    value() {
      return {
        act: this.act_id,
        student: this.student_id,
        year: this.year_id,
        simulation: this.simulator ? 1 : 0
      };
    },

    firstTable() {
      let x = this.data.reduce(function(group, x, index) {
        let iorder = parseInt(x.iorder);        
        if ( iorder < 36) {
          (group[iorder] = group[iorder] || []).push({
            arrayIndex: index,
            id: x.id,
            iorder: x.iorder,
            ordinary: x.ordinary,
            extraordinary: x.extraordinary,
            group_id: x.group_id,
            course_code: x.course_code,
            ordinary_period: x.ordinary_period,
            extraordinary_period: x.extraordinary_period,
            inscription_selective: x.inscription_selective,
            subject_name: x.subject_name,
            student: x.student,
            ordinaryMark: x.filter > 0 ? true : false,
            extraordinaryMark: x.filter_extra > 0 ? true : false
          });
        }
        // console.log("reduced element", group);
        return group;
      }, {});

      // let grouping = x.reduce(function(areas, x, index) {
      //   (areas['iorder'][index] = areas['iorder'][index] || []).push(x);
      // }, {});
      let grouped = {
        'lang': [],
        'social': [],
        'math': [],
        'sport': [],
      };
       // format numbers 

      console.log("this is x", x);

      for (const[index, obj] of Object.entries(x)) {
        for(const[inx, item] in obj) {       
          if(obj[inx]?.ordinary){
            obj[inx].ordinary = Math.round(obj[inx].ordinary).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
          }
          if(obj[inx]?.extraordinary){
            obj[inx].extraordinary = Math.round(obj[inx].extraordinary).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
          }
        }

        // this should correct the way its organized taking into account repeated years. ... this way the latest inscription for every course (11 or 12)
        // should carry the grades to the report




        // console.log(index);
        // console.log(obj);

        // format numbers 
        // for(const[inx, item] in obj) {
        //   console.log("corri el redondeo y ajuste", obj[inx]);
        //   if(obj[inx]?.ordinary){
        //   obj[inx].ordinary = Math.round(obj[inx].ordinary).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})

        //   obj[inx].extraordinary = Math.round(obj[inx].extraordinary).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})

        //   // console.log(Math.round(obj[inx].ordinary).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}));
          
        // }

        // if (this.simulator) {
        //   // check if second element (12th grade grades) comes and if it does ... eliminate values.
        //   if(obj.length == 2) {
        //     // console.log('wtf!');
        //     obj[1].ordinary = " ";
        //     obj[1].extraordinary = " ";
        //   }else {
        //     let obj_copy = Object.assign({}, obj[0]);  //{ ...obj[0] };
        //     obj_copy.ordinary = " ";
        //     obj_copy.extraordinary = " ";
        //     obj_copy.id = " ";
        //     let code = obj_copy.course_code
        //     obj_copy.course_code = code.replace('11', '12');
        //     obj.push(obj_copy);
            
        //   }
        // }

        if (index < 12) {
          grouped.lang.push(obj);
        }else if (index < 21) {
           grouped.social.push(obj);
        }else if (index < 31) {
           grouped.math.push(obj);
        }else {
           grouped.sport.push(obj);
        }
      }
      // console.log('everything I ever ', x);
      // console.log('everything I ever wanted ', grouped);
      return grouped;
    },

    secondTable() {
      let y = [];
      
      y = this.data.reduce((group, x, index) => {
        if (
          parseInt(x["iorder"]) > 35 &&
          parseInt(x["iorder"]) < 41 &&
          ((this.simulator && x["course_code"].includes('12')) || parseInt(x["extra_3"]) > 0 || parseInt(x["extra_4"]) > 0)
        ) {
          group.push({
            arrayIndex: index,
            id: x.id,
            iorder: x.iorder,
            extra3: x.extra_3,
            extra4: x.extra_4,
            group_id: x.group_id,
            course_code: x.course_code,
            subject_name: x.subject_name + " " + x.course_code,
            student: x.student
          });
        }
        // console.log("reduced element", group);
        return group;
      }, []);
      // console.log('life is one big road with a lot of signs ', y);
      
      return y;
    },

    secondTableNames() {
      let names = this.secondTable.reduce(function(names_group, x, index){
        names_group.push(x.subject_name);
        return names_group
      },[]);
      return names;
    },

    thisIsQ() {
      let mQ = this.data.reduce((q, current) => {
        if (parseInt(current["iorder"]) < 35) {
          if (!isNaN(parseInt(current.ordinary)) && parseInt(current.filter) != this.filter_id) {
            q = q + parseInt(current.ordinary);
          }

          if (!isNaN(parseInt(current.extraordinary)) && parseInt(current.filter_extra) != this.filter_id) {
            q = q + parseInt(current.extraordinary);
          }
        }
        // console.log('this is q', q);
        return q;
      }, 0);
      // console.log(this.data);
      return Math.round((mQ * 40) / 36);
    },

    thisIsA() {
      let ff = this.data.reduce((a, current) => {
        if (parseInt(current["iorder"]) > 35 && parseInt(current["iorder"]) < 41) {
          if (parseInt(current.extra3) > 0 && isNaN(parseInt(current.extra4))) {
            a = a + parseInt(current.extra3) * 4;
          }else if (parseInt(current.extra4) > 0 && isNaN(parseInt(current.extra3))) {
            a = a + parseInt(current.extra4) * 4;
          }else if (parseInt(current.extra3) > 0 && parseInt(current.extra4) > 0) {
            a = a + ((2*parseInt(current.extra3) + parseInt(current.extra4))/3)*4 ;
          }
        }
        return Math.round(a);
      }, 0);
      // console.log('this is ff', ff);
      return ff;
    },

    belegt() {
      return this.data.reduce((belegt, current, index) => {
        if (parseInt(current["iorder"]) < 35) {
          if ( parseInt(current.ordinary) > 0 ) {
            belegt = belegt + 1 ;
          }
          if ( parseInt(current.extraordinary) > 0 ) {
            belegt = belegt + 1 ;
          }
        }
        return belegt;
      }, 0);
    },
  
    punkte() {
       return this.data.reduce((punkte, current, index) => {
        if (parseInt(current["iorder"]) < 35) {
          if ( parseInt(current.ordinary) > 4  && current.filter == undefined) {
            punkte++;
          }
          if ( parseInt(current.extraordinary) > 4 && current.filter_extra == undefined) {
            punkte++;
          }
        }
        return punkte;
      }, 0);
    },

    gesamt() {
      return this.data.reduce((gesamt, current, index) => {
        if (parseInt(current["iorder"]) < 35) {
          if ( parseInt(current.ordinary) > 0 ) {
            gesamt = gesamt + parseInt(current.ordinary);
          }
          if ( parseInt(current.extraordinary) > 0 ) {
            gesamt = gesamt + parseInt(current.extraordinary);
          }
        }
        return gesamt;
      }, 0);
    },

    allcool() {
      if (this.belegt >= 40 && (this.belegt - this.marksAvailabe) == 36 && this.punkte >= 29 && this.gesamt >= 200) {
        return true;
      }
      return false;
    },

    marksAvailabe() {
      return this.data.reduce(function(totalMarked, current) {
        if (current.filter > 0) {
          totalMarked++;
        }

        if (current.filter_extra > 0) {
          totalMarked++;
        }
        return totalMarked;
      }, 0);
    }
  },

  watch: {
    thisIsQ(value) {
      this.getGrade();
    },
    thisIsA(value) {
      this.getGrade();
    }
  },

  methods: {
    async fetchData() {
      if(this.simulator) {
        console.log('QWUEIOQWE QWOEIKQW EQW EQWPE WDF< ', this.value);
      }
      this.data = await this.$api.getFirstTable(this.value);

      console.log(this.data);
    },

    async alterRecordData(record_id, field_name, value) {
      let obj = new Object();
      obj["recordId"] = record_id;
      obj[field_name] = value;
      await this.$api.postRecord(obj);
    },

    async addFilter(student, period, group_id, value) {
      let obj = new Object();
        obj["personId"] = student;
        obj["periodId"] = period;
        obj["groupId"] = group_id;
        obj["filterId"] = value;
      await this.$api.alterInscription(obj);
    },

    async getGrade() {
      // console.log(this.firstTable);
      let value = this.thisIsQ + this.thisIsA;
      if (value > 0){
        this.abiturGrade = await this.$api.getGrade({
          schemeId: this.scheme_id,
          value: this.thisIsQ + this.thisIsA
        });
      }
    },

    action(grade, field_name) {
      // console.log('aqui va el action y este es el grade', grade, field_name)
      if (field_name == "filter") {
        return !grade.ordinaryMark;
      } else if (field_name == "filter_extra") {
        return !grade.extraordinaryMark;
      }
      return false;
    },

    // tck tck <3
    toggle(grade, field_name, event) {
      let marked = this.action(grade, field_name);
        let value = marked ? this.filter_id : "NULL";
        if (!marked || this.marksAvailabe < this.max_filtered) {
          let period =
            field_name == "filter"
              ? grade.ordinary_period
              : grade.extraordinary_period;
  
          // console.log('el valor del grade es ', grade);
          // console.log('el valor del field es ', field_name);
          // console.log('el valor del filtro es ', value);
          // if (!this.simulator) {
            this.alterRecordData(grade.id, field_name, value).then(
              this.addFilter(grade.student, period, grade.group_id, value).then(
                (this.data[grade.arrayIndex][field_name] =
                  value == "NULL" ? null : value)
              )
            );
          // }
        }
        // console.log(
        //   "ouch",
        //   grade.id,
        //   grade.student,
        //   period,
        //   grade.group_id,
        //   field_name
        // );
    },

    firstAverage(){
      console.log('corro');
      let averages = [];
      this.data.reduce((group, current, index) => {
        let iorder = parseInt(current["iorder"]);
        if ( iorder < 36) {
          if (current.ordinary && current.extraordinary && current.course_code.includes('11')) {
            let avg1 = (parseInt(current.ordinary)+parseInt(current.extraordinary))/2;
            let avg2 = (parseInt(current.ordinary)+parseInt(current.extraordinary)+ avg1)/3;
            averages[iorder] =({
              avg1: avg1,
              avg2: avg2,
              order: iorder
            });
          }else if(current.course_code.includes('12') && typeof(averages[iorder]) !== 'undefined') {
            console.log('flag2');
            console.log(averages);
            this.data[index].ordinary = averages[iorder].avg1;
            this.data[index].extraordinary = averages[iorder].avg2;
          }
            return averages;
        }
      }, []);
    }
  }
};
</script>

<style lang="scss">
.table2_container {
  display: flex;
  justify-content: space-around;
  & > div {
    width: 50%;
  }
}

.halbja {
  text-align: center;
  margin: auto;
  & > .data {
    border: 1px solid #ccc;
    padding: 10px;
    display: inline-block;
    width: 50%;
  }
}

.abiturmatrix1,
.abiturmatrix2,
.abiturhalbja {
  border-collapse: collapse;
  & > thead {
    & > tr {
      & > td {
        text-align: center;
        font-size: 10pt;
      }
    }
  }
  &:is(.abiturhalbja) > tr { 
    & > td {
      border: none;
    }
  }
  & > tr {
    & > td {
      background-color: none;
      font-size: 10pt;
      border: 1px solid #cccccc;
      &.matrixcell {
        text-align: center;
        padding: 8px;
        &:hover {
          border: 1px solid #cccccc;
        }
        &.clickable {
          cursor: pointer;
        }
        background-color: #66bb6a;
      }
      &.activeFilter {
        // background-color: #66bb6a;
        background-color: white;
        &:after {
          content: ")";
        }
        &:before {
          content: "(";
        }
        &:hover {
          border: 1px solid #0b570f;
        }
      }
      &.centered {
        text-align: center;
      }
      &.left {
        text-align: left;
      }
      &.big {
        font-size: 25pt;
        font-weight: bold;
      }
      &.bless {
        border: 0px;
      }
      &.error {
        color: red;
        font-size: 20px;
        font-weight: bolder;
      }
      &.enclosed {
        width: 25px;
        height: 25px;
        border: 1px solid #ccc;
        text-align: center;
      }
       &.empty {
        height: 25px;
      }
    }
  }
}
</style>