export default httpClient => ({

  getFirstTable(query) {
    return httpClient.get('/v3/academic/report/official/act/abitur/getAbiturData', query);
  },

  postRecord(input) {
    return httpClient.post('/v3/academic/report/official/act/abitur/postRecord', input);
  },

  alterInscription(input) {
    return httpClient.post('/v3/academic/course/group/inscription/addfilter', input);
  },

  getGrade(query) {
    return httpClient.get('/v3/academic/scheme/getGrade', query);
  }


});